/* Стили для компонента Filters */

.filterContainer {
  margin-bottom: 20px;
}

.filterToggle {
  margin-right: 10px;
  padding: 6px 10px;
  background-color: #1a2d7d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.filterGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.filterGroup input {
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 12px;
  width: 180px;
}

.filterGroup button {
  padding: 6px 10px;
  background-color: #e0e0e0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s ease;
}

.filterGroup button:hover {
  background-color: #143566;
  color: white;
}

.active {
  background-color: #1a2d7d;
  color: white;
}

.separator {
  border-left: 2px solid #1a2d7d;
  height: 20px;
  margin: 0 10px;
}
