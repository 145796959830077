/* Стили для компонента ParticipantsContainer */

.container {
  font-family: "Arial", sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 5px 0;
  color: #333;
}

h1 {
  text-align: center;
  color: #1a2d7d;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterToggle {
  margin-right: 10px;
  padding: 6px 10px;
  background-color: #1a2d7d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}

.statisticsSortContainer {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 12px;
}

.sortOptions {
  display: flex;
  gap: 10px;
}

.sortOption {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.sortOption i {
  font-size: 12px;
}

.downloadButton {
  padding: 6px 10px;
  background-color: #1a2d7d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.downloadButton i {
  font-size: 12px;
}

.downloadButton:hover {
  background-color: #143566;
}

.participantsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  padding: 0;
}
